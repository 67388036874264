export default {
  text: 'white',
  primary: '#b72437',
  secondary: '#dcb6c1',
  tertiary: '#f7f6d4',
  background: '#069c83',
  backgroundSecondary: '#fdfdfd',
  light: '#FFF',
  dark: '#020303',
};
