export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    ':hover': {
      backgroundColor: '#320f0a',
    },
  },
  secondary: {
    backgroundColor: '#320f0a',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white',
    },
  },
}
