export default {
  heading: 'Lobster, cursive',
  body: 'Bebas Neue, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Bungee Shade, san-serif',
  display2: 'Monoton, san-serif',
  googleFonts: [
    'Source Sans Pro:100,200,300,400,500,600,700,800,900',
    'Bebas Neue',
    'Lobster',
    'Monoton',
    'Bungee Shade',
  ],
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
};
