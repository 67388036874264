export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      // display: 'none',
      position: 'static',
      margin: '0rem auto 0rem 0rem',
    },
    '.logo': {
      // display: 'none',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['70px', '90px', '90px', ''],
        filter: 'unset',
        // maxWidth: ['', '', 'unset', 'unset'],
      },
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        maxHeight: ['40px', '60px', '60px', ''],

        filter: 'unset',
      },
    },

    '.container': {
      padding: '2rem 3rem',
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '0rem',
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 2rem',
      backgroundColor: 'background',
      // width: 'fit-content',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      borderBottom: '1px solid',
      borderColor: 'primary',
      color: 'white',
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'primary',
      },
    },

    '.smallNavMenu': {
      '.navItem': {
        textShadow: '1px 1px 10px black',
        color: 'white',
        a: {
          fontFamily: 'body',
          fontSize: '1.25rem',
          ':hover': {
            textDecoration: 'underline',
            color: 'white',
          },
        },
      },
      '.react-reveal': {
        opacity: '1',
      },
    },

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem',
      },
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light',
      },
    },
    '.navMenuLogo': {
      display: 'none',
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline',
          },
        },
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto'],
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
        },
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white',
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right',
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white',
            },
          },
        },
      },
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh',
    },
  },

  footer: {
    // borderTop: '2px solid',
    // borderColor: 'primary',
    // '.address': {
    //   display: 'none',
    // },
    backgroundColor: 'background',
    '.columnLogo': {
      '.image': {
        // filter: 'brightness(0) invert(1)',
      },
    },
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '', '4rem'],
    textTransform: 'uppercase',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '1.5rem',
    color: 'inherit',
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'display',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '', '2rem'],
    marginBottom: '1.5rem',
    backgroundColor: 'primary',
    textAlign: 'center',
    padding: '1rem 7.5%',
    color: 'text',
    clipPath: 'polygon(0 0, 100% 0, 90% 100%, 10% 100%)',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '::before': {
      content: "''",
      padding: '0rem 1rem',
      height: '20px',
      width: '40px',
      borderTop: '4px solid',
      borderBottom: '4px solid',
      transform: 'translate(-50%)',
    },
    '::after': {
      content: "''",
      padding: '0rem 1rem',
      height: '20px',
      width: '40px',
      borderTop: '4px solid',
      borderBottom: '4px solid',
      transform: 'translate(50%)',
    },
    boxShadow: '0px 0px 10px black',
  },
  text: {
    textAlign: 'center',
    p: {
      textAlign: 'center',
      color: 'inherit',

      fontSize: 'inherit',
    },
  },

  sideBySide1: {
    padding: '10vh 1rem',
    background: 'transparent',

    '.lazyload-wrapper': {
      position: 'relative',
      '::before': {
        content: "''",
        height: '110%',
        width: '100%',
        backgroundColor: 'tertiary',
        borderRadius: '500px',
        position: 'absolute',
        top: '0rem',
        left: '-2rem',
        zIndex: '-1',
      },
      img: {
        maxHeight: '75vh',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 10% 90%)',
      },
    },
    '.content': {
      alignItems: 'center',
      // clipPath: 'polygon(0 0, 100% 0, 90% 90%, 0 100%)',
      // backgroundColor: 'tertiary',
      padding: ['2rem 1rem', '2rem', '3rem', '4rem'],
    },
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
    },
    a: {
      '.ctaButton': {
        variant: 'buttons.primary',
      },
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      img: {
        maxHeight: '75vh',
        clipPath: 'polygon(0 0, 100% 0, 90% 90%, 0 100%)',
      },
      position: 'relative',

      '::before': {
        content: "''",
        height: '110%',
        width: '100%',
        backgroundColor: 'primary',
        borderRadius: '500px',
        position: 'absolute',
        top: '0rem',
        left: '2rem',
        zIndex: '0',
      },
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.title': {
      variant: 'customVariants.title',
      fontFamily: 'display2',
      color: 'white',
      textShadow: '2px 2px 10px black',
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
    },

    '.section': {
      '> div:nth-of-type(1)': {
        order: '2',
      },
    },
  },

  homepageHeroShout: {},

  homepageShout: {
    backgroundColor: 'tertiary',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'tertiary'],
    },
    '.text': {
      fontFamily: 'display',
      fontSize: ['1.6rem', '', '2rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'tertiary'],
      fontFamily: 'display',
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['background', '', '', 'background'],
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'tertiary'],
      fontSize: '0.8rem',
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'tertiary'],
      order: ['', '', '4'],
    },
    // borderTop: '2px solid tertiary',
    // borderBottom: '2px solid tertiary',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2',
    backgroundColor: 'tertiary',
    color: 'primary',
    border: 'double 16px',
    borderColor: 'primary',
  },

  homepageEvent: {
    variant: 'customVariants.sideBySide1',
  },

  homepageBoxes: {
    '.box': {
      width: ['calc(50% - 1rem)', '', 'calc(33.3% - 1rem)'],
      '.image': {
        height: '150px',
        objectFit: 'contain',
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem'],
        color: 'primary',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1rem', '1.25rem', '1.25rem', '1.25rem'],
      },
      '.text': {
        variant: 'customVariants.text',
      },
    },
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2',
    backgroundColor: 'secondary',
    color: 'white',
    border: 'double 16px',
    borderColor: 'primary',
  },

  homepageContactUs: {
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      variant: 'customVariants.title',
      order: '1',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
    },
    '.text': {
      order: '3',
    },
    a: {
      order: '5',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
    },
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsCTAActive': {
      textDecoration: 'none',
    },
    '.albumTitle': {
      padding: '0.5rem',
      backgroundColor: 'black',
      borderColor: 'black',
      ':hover': {
        backgroundColor: 'primary',
        borderColor: 'primary',
      },
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2',
      },
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        // filter: 'brightness(0) invert(1)',
      },
    },

    '.eventItemImage': {
      minHeight: 'unset',
    },
    '.eventDays, .eventDate': {
      color: 'primary',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    backgroundColor: 'black',
    backgroundImage:
      'url(https://www.transparenttextures.com/patterns/mooning.png)',
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    h2: {
      textTransform: 'uppercase',
      color: 'light',
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white',
      },
    },
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      color: 'primary',
      marginBottom: '1.5rem',
      borderBottom: '2px solid',
      borderColor: 'primary',
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid',
    },
  },
};
